import styled from 'styled-components';

const VisitorSectionWrapper = styled.section`
    display: flex;
    ${({ reverse }) => reverse && 'flex-direction: row-reverse;'}
    position: relative;
    justify-content: space-between;

    padding: 120px 0 60px;
    @media screen and (max-width: 1440px) {
        padding: 50px 0px;
    }
    @media screen and (max-width: 768px) {
        padding: 60px 0;
    }
    @media screen and (max-width: 480px) {
        padding: 50px 0 10px;
    }

    @media (max-width: 767px) {
        min-height: auto;
        display: block;
    }

    .textWrapper {
        width: 47%;

        @media (max-width: 767px) {
            width: 100%;
            margin-top: 25px;
        }
    }
`;

const SectionObject = styled.div`
    width: 49%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 767px) {
        width: 100%;
        position: relative;
        height: auto;
        top: auto;
        left: auto;
    }
    .objectWrapper {
        margin-right: auto;
        position: relative;

        &:hover {
            opacity: 0.96;
        }

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 10px;
            top: 10px;
            transition: transform 330ms ease-in-out;
            background: linear-gradient(
                125deg,
                rgb(91 109 220 / 69%) 0%,
                rgb(135 139 218 / 74%) 100%
            );
            z-index: -1;
        }
        &:hover:before {
            transform: translate(-20px, -20px);
        }
    }
`;

export { SectionObject };

export default VisitorSectionWrapper;
