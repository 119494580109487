import React from 'react';
import { Icon } from 'react-icons-kit';
import { ic_check_circle } from 'react-icons-kit/md/ic_check_circle';

import Container from '../../common/components/UI/ContainerTwo';
import Heading from '../../common/components/Heading';
import List from '../../common/components/List';
import { __ } from '../../common/utils/LanguageHelper';

import SectionWrapper, {
    Section,
    Content,
    Illustration,
    ListGroup,
} from './SiteFeatures.style';
import AwasulScreensStack_1 from '../../common/assets/image/awasul_screens_stack.png';
import AwasulScreensStack_3 from '../../common/assets/image/awasul_screens_stack_3.png';
import ImagesTransition from '../../common/components/ImagesTransition';

const SiteFeatures = ({ lang }) => {
    const FEATURES = [
        {
            id: 1,
            title: __('منتج سعودي', 'Saudi Product'),
        },
        {
            id: 2,
            title: __(
                'نظام آمن حسب معايير الأمن والأمان',
                'Security system according to the security and safety standards'
            ),
        },
        {
            id: 3,
            title: __(
                'منصة ذكية للعمل مع كافة الأجهزة الذكية',
                'Smart platform for working with all smart devices'
            ),
        },
        {
            id: 4,
            title: __(
                'إمكانية استضافتها على خادم العميل الرئيسي أو عبر الحوسبة السحابية',
                'It can be place on the client server or on  Cloud'
            ),
        },
        {
            id: 5,
            title: __(
                'تدعم التواصل الداخلي والعمل عن بعد والتفاعل الإيجابي في بيئة العمل الداخلية',
                'It supports internal communication & work remotely and interact in a positive work environment.'
            ),
        },
        {
            id: 6,
            title: __(
                'تساعد على تبادل الآراء والأفكار وتعزيز العمل الجماعي',
                'It helps to exchange opinions and ideas and promoting collective action '
            ),
        },
        {
            id: 7,
            title: __(
                'تدعم خدمات الموارد البشرية والدعم اللوجستي لإدارة المشاريع',
                'It supports human resources and logistical support for project management.'
            ),
        },
        {
            id: 8,
            title: __(
                'تحقق التواصل الاجتماعي الذي يدعم جوانب الإثراء والمعرفة ومستوى الإنتماء والشفافية لدى الموظفين',
                'Achieving social networking, which supports enrichment, knowledge, belonging and transparency among staff member.'
            ),
        },
        {
            id: 9,
            title: __(
                'تساهم في تحسين جودة الإنتاج والذي ينعكس على رضا العميل وزيادة الدخل',
                'It contributes to improving the quality of production, which is reflected in customer satisfaction and income growth.'
            ),
        },
        {
            id: 10,
            title: __(
                'تمنح تجربة مستخدم أكثر تشويقا ومتعة لممارسة الأعمال',
                'It provides a more interesting and enjoyable user experience to do business.'
            ),
        },
    ];

    const PreviewImages = [
        {
            title: 'Awasul',
            imageUrl: AwasulScreensStack_1,
        },
        {
            title: 'Awasul',
            imageUrl: AwasulScreensStack_3,
        },
    ];

    return (
        <SectionWrapper id="site_features">
            <Container>
                <Section>
                    <Illustration lang={lang}>
                        <ImagesTransition images={PreviewImages} />
                    </Illustration>
                    <Content>
                        <Heading
                            as="h2"
                            content={__('مميزات أواصل', 'Awasul Features')}
                        />
                        <ListGroup>
                            {FEATURES.map((item) => (
                                <List
                                    className="list-item"
                                    key={item.id}
                                    text={item.title}
                                    icon={
                                        <Icon
                                            icon={ic_check_circle}
                                            size={18}
                                            className="list-icon"
                                            style={{
                                                margin: __(
                                                    '0 0 0 10px',
                                                    '0 10px 0 0'
                                                ),
                                            }}
                                        />
                                    }
                                />
                            ))}
                        </ListGroup>
                    </Content>
                </Section>
            </Container>
        </SectionWrapper>
    );
};

export default SiteFeatures;
