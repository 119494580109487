import React from 'react';
import AccessCard from './AccessCard';
import AccessSectionWrapper from './AccessSection.style';
import { __ } from '../../common/utils/LanguageHelper';

import ServerGif from '../../common/assets/image/server_1.gif';
import CloudGif from '../../common/assets/image/cloud.gif';

export default function AccessSection() {
    return (
        <AccessSectionWrapper>
            <AccessCard
                title={__('خادم المنشأة الرئيسي Server', 'Server')}
                imageUrl={ServerGif}
            />
            <AccessCard
                title={__('نظام الحوسبة السحابية Cloud', 'Cloud')}
                imageUrl={CloudGif}
            />
        </AccessSectionWrapper>
    );
}
