import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Image from '../../common/components/Image';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import FeatureBlock from '../../common/components/FeatureBlock';
import Container from '../../common/components/UI/Container';
import Particles from '../Particle';
import BannerWrapper, { BannerObject } from './bannerSection.style';
import { __ } from '../../common/utils/LanguageHelper';
import { Link } from 'gatsby-plugin-intl';
import ImagesTransition from '../../common/components/ImagesTransition';

import BannerObject1 from '../../common/assets/image/banner/bannerObject1.png';
import Screen1 from '../../common/assets/image/banner/Awasul_banner_image.png';
import Screen2 from '../../common/assets/image/Awasul_banner_2.png';

const BannerSection = ({
    row,
    col,
    title,
    lang,
    btnStyle,
    description,
    discountText,
    discountAmount,
    outlineBtnStyle,
}) => {
    const ButtonGroup = () => (
        <>
            <Link to="/login">
                <Button
                    title={__('احجز نسختك المجانية', 'FREE TRAIL')}
                    {...btnStyle}
                />
            </Link>
            {/* <Button
                className="outlined"
                title={__('مشاهدة فيديو', 'WATCH VIDEO')}
                variant="outlined"
                {...outlineBtnStyle}
            />*/}
        </>
    );

    const BannerImages = [
        {
            title: 'Awasul website and mobile app',
            imageUrl: Screen1,
        },
        {
            title: 'Awasul website and mobile app',
            imageUrl: Screen2,
        },
    ];

    return (
        <BannerWrapper id="banner_section">
            <Particles />
            <Container>
                <Box className="row" {...row}>
                    <Box className="col" {...col}>
                        <div
                            style={{
                                textAlign: 'center',
                                width: 'max-content',
                            }}
                        >
                            <Text content="30%" {...discountAmount} />

                            <Text
                                content={__(
                                    'تخفيض بالإضافة إلى ثلاث أشهر دعم فني مجانا إلى جميع الباقات',
                                    'Discount plus three months free technical support to all packages'
                                )}
                                {...discountText}
                            />
                        </div>

                        <FeatureBlock
                            title={
                                <Heading
                                    content={__(
                                        <>
                                            مرحبا بك في{' '}
                                            <span style={{ color: '#6673dd' }}>
                                                أواصل
                                            </span>
                                        </>,
                                        <>
                                            Welcome to{' '}
                                            <span style={{ color: '#6673dd' }}>
                                                Awasul
                                            </span>
                                        </>
                                    )}
                                    {...title}
                                />
                            }
                            description={
                                <Text
                                    content={__(
                                        'أول منصة سعودية في التواصل الداخلي المؤسسي  (إنترانت)  والمنتج الإجتماعي الأول من نوعه في العالم العربي ، موجه إلى المنشآت الحكومية والخاصة، والتي تمكَن موظفي المنشآت من تحقيق التواصل الداخلي الفعال والعمل عن بعد مع برنامج احترافي في إدارة المشاريع وتنبيهات الطوارئ وحزمة من الخدمات الإلكترونية الأخرى.',
                                        'The first internal communication platform (Intranet) and the first Saudi Arabian e-social product addressed to all governmental and private organizations, which enables staff member to communicate internally and to work remotely with all its users around the world safely and easily.'
                                    )}
                                    {...description}
                                />
                            }
                            button={<ButtonGroup />}
                        />
                    </Box>
                </Box>
            </Container>
            <BannerObject lang={lang}>
                <div className="objectWrapper">
                    <div className="shapeWrapper">
                        <Image src={BannerObject1} alt="BannerObject1" />
                    </div>
                    <div className="dashboardWrapper" lang={lang}>
                        <ImagesTransition
                            images={BannerImages}
                            className="bannerImage"
                        />
                    </div>
                </div>
            </BannerObject>
        </BannerWrapper>
    );
};

BannerSection.propTypes = {
    title: PropTypes.object,
    btnStyle: PropTypes.object,
    description: PropTypes.object,
    contentStyle: PropTypes.object,
    discountText: PropTypes.object,
    discountAmount: PropTypes.object,
    outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
    row: {
        flexBox: true,
        flexWrap: 'wrap',
        ml: '-15px',
        mr: '-15px',
        alignItems: 'center',
    },
    col: {
        pr: '15px',
        pl: '15px',
    },
    title: {
        fontSize: ['25px', '30px', '45px', '55px'],
        fontWeight: '700',
        color: '#0f2137',
        letterSpacing: '-0.025em',
        paddingTop: '10px',
        mb: ['20px', '25px'],
        lineHeight: '1.5',
    },
    description: {
        fontSize: '16px',
        color: '#343d48cc',
        lineHeight: '1.75',
        mb: '0',
        textAlign: 'justify',
    },
    btnStyle: {
        minWidth: ['120px', '120px', '120px', '156px'],
        fontSize: ['13px', '14px'],
        fontWeight: '500',
        colors: 'primaryWithBg',
    },
    outlineBtnStyle: {
        minWidth: '156px',
        fontSize: '14px',
        fontWeight: '500',
        color: '#5167db',
        ml: '18px',
    },
    discountAmount: {
        fontSize: '50px',
        display: 'block',
        color: '#f52826',
        mb: 0,
        as: 'span',
        mr: '0.4em',
        fontWeight: 700,
    },
    discountText: {
        fontSize: ['13px', '14px'],
        color: '#0f2137',
        textAlign: 'justify',
        mb: 0,
        as: 'span',
        fontWeight: 500,
    },
};

export default BannerSection;
