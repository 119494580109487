import styled from 'styled-components';

const JoinTrailArea = styled.div`
    width: 100%;
    padding: 75px 0;
    overflow: hidden;
    @media only screen and (max-width: 1366px) {
        padding: 60px 0;
    }
    @media only screen and (max-width: 667px) {
        padding: 45px 20px;
    }
`;

export const ContentWrapper = styled.div`
    margin: 0 auto;
    text-align: center;

    img {
        display: inline-block;
        max-width: 230px;
        @media only screen and (max-width: 667px) {
            margin-bottom: 27px;
            max-width: 50%;
        }
    }

    h2 {
        font-size: 33px;
        line-height: 60px;
        font-weight: 400;
        color: #0f2137;
        @media only screen and (max-width: 1366px) {
            font-size: 32px;
            line-height: 40px;
            letter-spacing: -1px;
            margin-bottom: 20px;
        }
        @media only screen and (max-width: 667px) {
            font-size: 28px;
            line-height: 38px;
            letter-spacing: -0.5px;
            margin-bottom: 15px;
            padding: 0 15px;
        }
    }
`;

export default JoinTrailArea;
