import styled from 'styled-components';

const ServicesSectionWrapper = styled.div`
    ${({ isMobile }) =>
        isMobile !== true &&
        `display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: 1600px;`}

    margin-top: 10px;
    width: 100vw;

    .menu-wrapper--inner {
        ${({ lang }) => lang === 'ar' && ' text-align: right !important;'}
    }
`;

export default ServicesSectionWrapper;
