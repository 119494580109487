import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs, { TabPane } from 'rc-tabs';
import 'rc-tabs/assets/index.css';
import Box from '../../common/components/Box';
import Heading from '../../common/components/Heading';
import Image from '../../common/components/Image';
import Container from '../../common/components/UI/Container';
import { __ } from '../../common/utils/LanguageHelper';
import SectionWrapper from './updateScreen.style';

// Icons
import { HiOutlineUserGroup } from '@react-icons/all-files/hi/HiOutlineUserGroup';
import { AiOutlineFundProjectionScreen } from '@react-icons/all-files/ai/AiOutlineFundProjectionScreen';
import { FiAlertTriangle } from '@react-icons/all-files/fi/FiAlertTriangle';
import { ImFilesEmpty } from '@react-icons/all-files/im/ImFilesEmpty';
import { HiOutlineChat } from '@react-icons/all-files/hi/HiOutlineChat';

// Screenshots
import FilesScreen from '../../common/assets/image/Awasul_web_screens/files.png';
import ProjectManagementScreen from '../../common/assets/image/Awasul_web_screens/projects.png';
import ChatScreen from '../../common/assets/image/Awasul_web_screens/chat.png';
import GroupsScreen from '../../common/assets/image/Awasul_web_screens/Awasul_groups.png';
import WarningsScreen from '../../common/assets/image/Awasul_web_screens/alerts.png';

const UpdateScreen = ({ secTitleWrapper, secHeading }) => {
    const [activeKey, setActiveKey] = useState('1');

    const iconsStyles = {
        style: { fontSize: 24, marginBottom: -8, marginLeft: 5 },
    };

    const SCREENSHOTS = [
        {
            icon: <AiOutlineFundProjectionScreen {...iconsStyles} />,
            title: __('إدارة المشاريع', 'Project management'),
            image: ProjectManagementScreen,
        },
        {
            icon: <HiOutlineChat {...iconsStyles} />,
            title: __('المحادثات', 'Chat'),
            image: ChatScreen,
        },
        {
            icon: <FiAlertTriangle {...iconsStyles} />,
            title: __('التحذيرات', 'Warnings'),
            image: WarningsScreen,
        },
        {
            icon: <ImFilesEmpty {...iconsStyles} />,
            title: __('الملفات', 'Files'),
            image: FilesScreen,
        },
        {
            icon: <HiOutlineUserGroup {...iconsStyles} />,
            title: __('المجوعات', 'Groups'),
            image: GroupsScreen,
        },
    ];

    useEffect(() => {
        const timer = setTimeout(() => {
            if (+activeKey + 1 > SCREENSHOTS.length) {
                setActiveKey('1');
            } else {
                setActiveKey((+activeKey + 1).toString());
            }
        }, 2500);
        return () => clearTimeout(timer);
    }, [activeKey]);

    return (
        <SectionWrapper id="screenshot_section">
            <Container>
                <Box {...secTitleWrapper}>
                    <Heading
                        {...secHeading}
                        content={__(
                            'تعدد المميزات و سهولة الاستخدام',
                            'Multiple features and ease of use'
                        )}
                    />
                </Box>
                <Tabs className="update-screen-tab" activeKey={activeKey}>
                    {SCREENSHOTS.map(({ title, icon, image }, index) => (
                        <TabPane
                            tab={
                                <>
                                    {icon}
                                    {title}
                                </>
                            }
                            className="tab-pane"
                            key={index + 1}
                        >
                            <Image
                                src={image}
                                style={{
                                    objectFit: 'contain',
                                }}
                                alt={`screenshot-${index + 1}`}
                            />
                        </TabPane>
                    ))}
                </Tabs>
            </Container>
        </SectionWrapper>
    );
};

UpdateScreen.propTypes = {
    secTitleWrapper: PropTypes.object,
    secText: PropTypes.object,
    secHeading: PropTypes.object,
};

UpdateScreen.defaultProps = {
    secTitleWrapper: {
        mb: ['50px', '15px'],
    },
    secHeading: {
        textAlign: 'center',
        fontSize: ['20px', '24px', '36px', '40px'],
        fontWeight: '400',
        color: '#0f2137',
        letterSpacing: '-0.025em',
        mb: '0',
        ml: 'auto',
        mr: 'auto',
        lineHeight: '1.12',
        width: '540px',
        maxWidth: '100%',
    },
};

export default UpdateScreen;
