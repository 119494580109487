import styled from 'styled-components';

const PartnerHistoryWrapper = styled.section`
    position: relative;
    overflow: hidden;

    margin: 100px 0 60px;

    @media screen and (max-width: 1440px) {
        margin: 70px 0;
    }
    @media screen and (max-width: 768px) {
        margin: 50px 0;
    }
    @media screen and (max-width: 480px) {
        margin: 60px 0 10px;
    }

    @media only screen and (max-width: 1200px) {
        min-height: 500px;
    }
    @media only screen and (max-width: 991px) {
        min-height: 370px;
    }

    .feature__block {
        .reusecore__button {
            transition: all 0.3s ease;
            &:hover {
                opacity: 0.85;
            }
        }
    }
`;

const CounterUpArea = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;
    @media (max-width: 990px) {
        margin-top: 50px;
        padding-left: 0;
        margin-left: -25px;
    }
    @media (max-width: 400px) {
        margin-left: 0;
    }
    .card {
        box-shadow: none;
        width: calc(50% - 25px);
        margin-left: 25px;
        margin-bottom: 27px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: box-shadow 0.3s ease-in-out;
        z-index: 1;
        cursor: pointer;
        @media (max-width: 480px) {
            padding: 20px;
        }
        @media (max-width: 360px) {
            width: 100%;
            margin-left: 0;
        }

        img {
            max-width: 180px;
            @media (max-width: 991px) {
                max-width: 150px;
            }
            @media (max-width: 767px) {
                max-width: 150px;
            }
        }

        &:nth-child(even) {
            position: relative;
            top: 22px;
            @media (max-width: 400px) {
                top: 0;
            }
        }
    }
`;

export { CounterUpArea };
export default PartnerHistoryWrapper;
