import React from 'react';
import ServiceCard from './ServiceCard';
import ServicesSectionWrapper from './ServicesSection.style';
import Fade from 'react-reveal/Fade';
import { __ } from '../../common/utils/LanguageHelper';
import { useMediaQuery } from '@material-ui/core';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { useIntl } from 'gatsby-plugin-intl';

import BellIcon from '../../common/assets/image/bell.svg';
import BrowserIcon from '../../common/assets/image/browser.svg';
import ConnectionIcon from '../../common/assets/image/connection.svg';
import NetworkIcon from '../../common/assets/image/network.svg';
import ProjectManagementIcon from '../../common/assets/image/project-management.svg';
import Heading from '../../common/components/Heading';
import Text from '../../common/components/Text';

export default function ServicesSection() {
    const { locale } = useIntl();

    const SERVICES_DATA = [
        {
            id: 1,
            title: __('إنترانت داخلي للمنشأة', 'Corporate website'),
            description: __(
                'موقع إلكتروني خاص بالشركة قابل للتعديل',
                'Customizable website for the organization'
            ),
            image: BrowserIcon,
        },
        {
            id: 2,
            title: __('التواصل الداخلي', 'Internal communication'),
            description: __(
                'قناة للتواصل الداخلي المؤسسي و الإجتماعي',
                'Institutional and social internal communication channel'
            ),
            image: ProjectManagementIcon,
        },
        {
            id: 3,
            title: __('إدارة المشاريع', 'Project management'),
            description: __(
                'نظام إدارة المشاريع الاحترافي',
                'Organization and management of internal and external projects'
            ),
            image: ConnectionIcon,
        },
        {
            id: 4,
            title: __('تنبيهات الطوارئ', 'Emergency alerts'),
            description: __(
                'نظام تنبيهات الحالات الطارئة في مقرات العمل',
                'Part of the continuous monitoring process.'
            ),
            image: BellIcon,
        },
        {
            id: 5,
            title: __('الإدارة الإلكترونية', 'e-governance'),
            description: __(
                'تحويل الإدارة التقليديّة لكافّة الوظائف والمهام إلى إدارة إلكترونية',
                'Transformation of traditional management of all functions to electronic management'
            ),
            image: NetworkIcon,
        },
    ];

    const secText = {
        as: 'div',
        display: 'block',
        textAlign: 'center',
        fontSize: '17px',
        color: '#808081',
        mb: '0px',
    };

    const secHeading = {
        textAlign: 'center',
        fontSize: ['30px', '30px', '36px', '40px'],
        fontWeight: '400',
        color: '#0f2137',
        letterSpacing: '-0.025em',
        mt: '100px',
        mb: '10px',
        ml: 'auto',
        mr: 'auto',
        lineHeight: '1.12',
        width: '540px',
        maxWidth: '100%',
    };

    const renderServices = SERVICES_DATA.map(
        ({ id, title, description, image }) => (
            <ServiceCard
                title={title}
                description={description}
                imageUrl={image}
                lang={locale}
                key={id}
            />
        )
    );

    const isMobile = useMediaQuery('@media (max-width: 831px)');

    return (
        <>
            <div>
                <Heading {...secHeading} content={__('الخدمات', 'Services')} />

                <Text
                    {...secText}
                    content={__(
                        'منصة التواصل الداخلي الأكثر تخصصا للعمل عن بعد',
                        'Awasul packages'
                    )}
                />
            </div>

            <ServicesSectionWrapper isMobile={isMobile} lang={locale}>
                {isMobile ? (
                    <ScrollMenu data={renderServices} rtl={locale === 'ar'} />
                ) : (
                    <Fade up delay={100}>
                        {renderServices}
                    </Fade>
                )}
            </ServicesSectionWrapper>
        </>
    );
}
