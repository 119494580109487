import React from "react";
import Image from "../../../common/components/Image";
import AccessCardWrapper from "./AccessCard.style";

export default function AccessCard({ title, imageUrl }) {
    return (
        <AccessCardWrapper>
            <h2>{title}</h2>
            <Image src={imageUrl} className='image' alt={title} />
        </AccessCardWrapper>
    );
}
