import styled from 'styled-components';

const AccessCardWrapper = styled.div`
    margin-left: 20px;
    border-radius: 10px;
    max-width: 260px;
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    background-color: #fff;

    @media (max-width: 577px) {
        margin-top: 20px;
        margin-left: 0;
    }

    h2 {
        text-align: center;
        font-size: 22px;
        margin: 0 0 10px 0;
    }

    .image {
        width: 100%;
        border-radius: 10px;
    }
`;

export default AccessCardWrapper;
