import React from 'react';
import { Container, Grid, useMediaQuery } from '@material-ui/core';
import PricingCardItem from './PricingCardItem';
import Heading from '../../common/components/Heading';
import { __ } from '../../common/utils/LanguageHelper';

// Svg Icons
import InternalIcon from '../../common/assets/image/icon_Internal_communication.svg';
import CompanyIcon from '../../common/assets/image/icon_company.svg';
import AnalyzeIcon from '../../common/assets/image/icon_analyze.svg';
import InstitutionsIcon from '../../common/assets/image/icon_Institutions.svg';
import Text from '../../common/components/Text';

const Pricing = () => {
    const mediumSize = useMediaQuery('@media (max-width: 990px)');
    const smallSize = useMediaQuery('@media (max-width: 767px)');

    const rootStyles = {
        paddingTop: '80px',
        paddingBottom: mediumSize ? '60px' : smallSize ? '20px' : '80px',
    };

    const InternalCommunication = [
        {
            title: __('الصفحة الرئيسية للمنشأة', 'The facility home page'),
            content: __(
                'موقع خاص بالمنشأة (إنترانت) يظهر اسم المنشأة والهوية الخاصة بها وكلمة الرئيس التنفيذي وسياسة ورؤية ورسالة المنشأة و أخر الأخبار والإنجازات والمستندات والأنظمة الخاصة بإجراءات العمل',
                'An intranet site that shows the facility’s name, identity, CEO word, the facility’s policy, vision and mission, the latest news, achievements, documents and regulations for work procedures'
            ),
        },
        {
            title: __('المجتمع الوظيفي', 'The functional community'),
            content: [
                {
                    title: __('كلمة الرئيس التنفيذي', 'CEO word'),
                    description: __(
                        'كلمة الرئيس التنفيذي للمنشأة ',
                        'A word from the CEO of the facility'
                    ),
                },
                {
                    title: __('سياسات المنشأة', 'Facility policies'),
                    description: __(
                        'تعريف العاملين بالسياسات التي تتبعها المنشأة',
                        'Familiarizing employees with the policies followed by the facility'
                    ),
                },
                {
                    title: __('التبليغ عن مشاركة', 'Report a post'),
                    description: __(
                        'لن يتمكن الأشخاص من بناء مجتمع على منصة أواصل إلا عند الشعور بالأمان. ولذا تقع مسؤولية التبليغ عن إساءة استخدام المنصة والسلوكيات الضارة تجاه الآخرين على جميع العاملين من أجل اتخاذ الإجراء المناسب، على سبيل المثال، عرض تقديم مساعدة ، أو إزالة المحتوى ، أو إزالة أو حجب الوصول إلى ميزات محددة ، أو تعطيل الحساب ، أو التواصل مع الجهات الرسمية .',
                        'People will only be able to build a community on the I continue when they feel safe. Therefore, all employees are responsible for reporting misuse of the platform and harmful behaviors towards others in order to take appropriate action, for example, offering to provide assistance, removing content, removing or blocking access to specific features, disabling the account, or communicating with entities. Official.'
                    ),
                },
            ],
        },
        {
            title: __('الأنشطة ', 'Activities'),
            content: [
                {
                    title: __('المشاركات المختلفة', 'Various posts'),
                    description: __(
                        'صفحة شخصية للموظف – والتي يستطيع الموظف من خلالها التواصل مع الآخرين وتبادل المعلومات الشخصية والهوايات وتحميل الصور و الفيديوهات  وإنشاء مجموعات عامة أو خاصة. ',
                        "An employee's personal page - through which the employee can communicate with others, exchange personal information and hobbies, upload photos and videos, and create public or private groups."
                    ),
                },
                {
                    title: __('التذكيرات', 'Reminders'),
                    description: __(
                        'عرض التذكيرات الخاصة بالموظف لمساعدته على إتمام الأعمال المجدولة.',
                        'View employee reminders to help them complete scheduled work.'
                    ),
                },
            ],
        },
        {
            title: __('المحادثات ', 'Conversations'),
            content: [
                {
                    title: __('كتابيا ', 'In writing'),
                    description: __(
                        'عرض جميع الدردشات الخاصة بالموظف سواء الفردية أو الجماعية.',
                        'View all chats of an employee, whether individual or group.'
                    ),
                },
                {
                    title: __('مكالمات هاتفية', 'Phone calls'),
                    description: __(
                        'التواصل عبر الهاتف عن طريق دليل الهاتف و البحث عن الاسم أو الرقم الوظيفي أو المستوى التنظيمي في دليل المنشأة.',
                        'Contacting the phone via the phone book and searching for the name, job number, or organizational level in the establishment directory.'
                    ),
                },
            ],
        },
        {
            title: __('تبادل الخبرات ', 'Share experiences'),
            content: __(
                'تبادل الخبرات و المعلومات عن طريق طرح الإستفسارات و الإجابة عليها من قبل المختصين أو أصحاب الخبرات مع إمكانية البحث عن الإستفسارات المشابهة.',
                'Exchanging experiences and information by asking inquiries and answering them by specialists or experts with the ability to search for similar inquiries.'
            ),
        },
        {
            title: __('التقويم', 'Calendar'),
            content: [
                {
                    title: __('التقويم ', 'Calendar'),
                    description: __(
                        'عرض التقويم الخاص باليوم والأسبوع والشهر .',
                        'View the calendar for the day, week, and month.'
                    ),
                },
                {
                    title: __('التذكيرات', 'Reminders'),
                    description: __(
                        'جدولت تذكيرات خاصة بالموظف .',
                        'Schedule employee specific reminders.'
                    ),
                },
            ],
        },
        {
            title: __('الإدارة', 'Administration'),
            content: __(
                'كل ماله علاقة بالباقة.',
                "It's all about the package."
            ),
        },
        {
            title: __(
                'سعة التخزين 2GB للفرد',
                'Storage capacity is 2GB per person'
            ),
            content: __(
                'تمنح منصة أواصل لكل فرد من أفراد المنشأة سعة تخزين في الخادم (Server)مقداراها 2GB (100 GB إضافية لكل 10 ريال )',
                'Asel platform gives each member of the facility a storage capacity in the server of 2 GB - (an additional 100 GB for every 10 riyals)'
            ),
        },
    ];

    const Business = [
        {
            title: __('باقة التواصل الداخلي', 'Internal communication package'),
            content: '',
        },
        {
            title: __('المجتمع الوظيفي', 'The functional community'),
            content: [
                {
                    title: __('كلمة الرئيس التنفيذي', 'CEO word'),
                    description: __(
                        'كلمة الرئيس التنفيذي للمنشأة ',
                        'A word from the CEO of the facility'
                    ),
                },
                {
                    title: __('سياسات المنشأة', 'Facility policies'),
                    description: __(
                        'تعريف العاملين بالسياسات التي تتبعها المنشأة',
                        'Familiarizing employees with the policies followed by the facility'
                    ),
                },
                {
                    title: __('المركز الإعلامي (أخبار)', 'Media Center (News)'),
                    description: __(
                        'يتولى المركز الإعلامي نشر أخبار الشركة من أعمال و إنجازات من خلال عرض المقالات و الصور و مقاطع الفيديو .',
                        "The Media Center publishes the company's news of its work and achievements by displaying articles, photos and videos."
                    ),
                },
                {
                    title: __(
                        'المواضيع الأكثر نشاطا',
                        'The most active topics'
                    ),
                    description: __(
                        'إظهار المواضيع التي تستحوذ على أكبر عدد من المشاهدات و تواصل بين الموظفين .',
                        'Show the topics that get the most views and communicate between employees.'
                    ),
                },
            ],
        },
        {
            title: __('الأنشطة', 'Activities'),
            content: [
                {
                    title: __('المشاركات', 'Posts'),
                    description: __(
                        'صفحة شخصية للموظف – والتي يستطيع الموظف من خلالها التواصل مع الآخرين وتبادل المعلومات الشخصية والهوايات وتحميل الصور و الفيديوهات  وإنشاء مجموعات عامة أو خاصة.',
                        "An employee's personal page - through which the employee can communicate with others, exchange personal information and hobbies, upload photos and videos, and create public or private groups."
                    ),
                },
                {
                    title: __('التعميمات والحملات', 'Circulars and Campaigns'),
                    description: __(
                        'تخصيص التعميمات والحملات إلى موظف واحد أو مجموعة من الموظفين أو كل الموظفين مع تحديد المدة الزمنية و إختيار عدد مرات التكرار في اليوم وتحديد وقت النشر  مع إضافة متابعتها أو إلغائها أو كتابة تعليق أو البحث عن طريق عدة خيارات .',
                        'Allocating circulars and campaigns to one employee, group of employees, or all employees with specifying the time period and choosing the number of repetitions per day and determining the time of publication with adding or canceling their follow-up or writing a comment or searching by several options.'
                    ),
                },
                {
                    title: __('التذكيرات', 'Reminders'),
                    description: __(
                        'عرض التذكيرات الخاصة بالموظف لمساعدته على إتمام الأعمال المجدولة .',
                        'View employee reminders to help them complete scheduled work.'
                    ),
                },
                {
                    title: __(
                        'الترحيب بالتعيين الجديد',
                        'Welcome the new appointment'
                    ),
                    description: __(
                        'نشر رسالة ترحيب بالموظف المعين حديثاً مع إظهار كامل البيانات (صورة شخصية ,المسمى الوظيفي ,المؤهلات ....) .',
                        'Posting a welcome letter to the newly appointed employee showing all information (personal photo, job title, qualifications ....).'
                    ),
                },
            ],
        },
        {
            title: __('الإدارة', 'Administration'),
            content: __(
                'كل ماله علاقة بالباقة.',
                "It's all about the package."
            ),
        },
        {
            title: __('التدريب لعدد جلستين', 'Two sessions training'),
            content: __(
                'من المهم جداً توفير التدريب للمستخدمين الجدد لمنصة أواصل , نقدم تدريب مجاني لعدد جلستان غير محددة من الموظفين في المنشآت الخاصة للعميل أو عن بعد وتتناول المواد التدريبية وطرق استخدام عناصر منصة أواصل',
                "It is very important to provide training for new users of the platform. We offer free training for two sessions for an unlimited number of staff member at the client's facilities or remotely. Address ways and means to use AWASUL platform"
            ),
        },
        {
            title: __(
                'سعة التخذين 4GB للفرد',
                'The storage capacity is 4GB per person'
            ),
            content: __(
                'تمنح منصة أواصل لكل فرد من أفراد المنشأة سعة تخزين في الخادم (Server)مقداراها 4GB (100 GB إضافية لكل 10 ريال )',
                'Asel platform gives each member of the facility a storage capacity in the server of 4 GB - (an additional 100 GB for every 10 riyals)'
            ),
        },
    ];

    const ProfessionalBusiness = [
        {
            title: __('باقة الأعمال', 'Business package.'),
            content: '',
        },
        {
            title: __('المحادثات ', 'Conversations'),
            content: [
                {
                    title: __('كتابيا ', 'In writing'),
                    description: __(
                        'عرض جميع الدردشات الخاصة بالموظف سواء الفردية أو الجماعية.',
                        'View all chats of an employee, whether individual or group.'
                    ),
                },
                {
                    title: __('مكالمات هاتفية', 'Phone calls'),
                    description: __(
                        'التواصل عبر الهاتف عن طريق دليل الهاتف و البحث عن الاسم أو الرقم الوظيفي أو المستوى التنظيمي في دليل المنشأة.',
                        'Contacting the phone via the phone book and searching for the name, job number, or organizational level in the establishment directory.'
                    ),
                },
                {
                    title: __(
                        'اللقاءات الافتراضية(الصوتية والمرئية)',
                        'Virtual meetings (audio and video)'
                    ),
                    description: __(
                        'عمل اللقاءات و الاجتماعات وعرض الأعمال عبر الدائرة التلفزيوينة المغلقة.',
                        'Conducting meetings, meetings and presentation of works via closed-circuit television.'
                    ),
                },
            ],
        },
        {
            title: __('المجموعات', 'Groups'),
            content: [
                {
                    title: __(
                        'إنشاء مجموعات عامة أو خاصة من أجل سرعة تبادل المعلومات .',
                        'Create public or private groups for the speedy exchange of information.'
                    ),
                },
                {
                    title: __(
                        'عرض المجموعات و ترتيبها حسب الأحرف الأبجديه أو حسب الأحدث .',
                        'Show groups and arrange them alphabetically or according to the most recent.'
                    ),
                },
            ],
        },
        {
            title: __('الملفات ', 'Files'),
            content: [
                {
                    title: __(
                        'أرشفة المجلدات والملفات الصور والفيديوهات الخاصة بالمنشأة .',
                        'Archiving folders, files, photos and videos of the facility.'
                    ),
                },
                {
                    title: __(
                        'عرض المجلدات والملفات ومحتوياتها ومشاهدة التفاصيل الخاصة بها .',
                        'View folders and files and their contents and see their details.'
                    ),
                },
                {
                    title: __(
                        'إعادة تسمية المجلدات والملفات أو حذفها أو رفع نسخة جديدة أو تحميلها أو إرسال مشاركة .',
                        'Rename or delete folders and files, upload a new copy, upload or send a post.'
                    ),
                },
            ],
        },
        {
            title: __('الهيكل التنظيمي ', 'Organizational Structure'),
            content: __(
                'عرض جميع أقسام الشركة وأسماء الموظفين و وظائفهم',
                'Display all company departments, names of employees, and their jobs'
            ),
        },
        {
            title: __('تقويم المنشأة', 'Facility calendar'),
            content: __(
                'عرض جميع الأحداث الدينية و الوطنية و الخاصة بالمنشأة خلال السنة.',
                'Displaying all the religious, national and private events of the facility during the year.'
            ),
        },
        {
            title: __('الإدارة', 'Administration'),
            content: __(
                'كل ماله علاقة بالباقه + الإقرار الإلكتروني ) إرسال إقرار الكتروني إلى موظف أو  مجموعة من الموظفين أو جميع الموظفين )',
                'Everything related to the package + electronic acknowledgment (sending an electronic acknowledgment to an employee or a group of employees or all employees)'
            ),
        },
        {
            title: __('التدريب لعدد 3 جلسات', 'Three sessions training '),
            content: __(
                'من المهم جداً توفير التدريب للمستخدمين الجدد لمنصة أواصل , نقدم تدريب مجاني لعدد ثلاث جلسات لعدد غير محدد من الموظفين في المنشئآت الخاصة للعميل أو عن بعد وتتناول المواد التدريبية وطرق استخدام عناصر منصة أواصل',
                "It is very important to provide training for new users of the platform. We offer free training for three sessions for an unlimited number of staff member at the client's facilities or remotely. Address ways and means to use AWASUL platform"
            ),
        },
        {
            title: __(
                'سعة التخذين 8GB للفرد',
                'The storage capacity is 8GB per person'
            ),
            content: __(
                'تمنح منصة أواصل لكل فرد من أفراد المنشأة سعة تخزين في الخادم (Server)مقداراها 8GB (100 GB إضافية لكل 10 ريال )',
                'Asel platform gives each member of the facility a storage capacity in the server of 8 GB - (an additional 100 GB for every 10 riyals)'
            ),
        },
    ];

    const Enterprise = [
        {
            title: __('باقة الأعمال المحترفة', 'Professional business package'),
            content: '',
        },
        {
            title: __('المشاريع', 'Project'),
            content: [
                {
                    title: __(
                        'يتمكن جميع أفراد المنشاءة من إنشاء مشروع أو تكليف شخص آخر بمشروع ما مع إضافة مستندات وصور وفيديوهات.',
                        'All the facility personnel can create a project or assign another person to a project with the addition of documents, photos and videos.'
                    ),
                },
                {
                    title: __(
                        'عرض قائمة المشاريع وحالتها و المهام المرتبطه بها وأعضاء الفريق. ',
                        'View a list of projects, their status, associated tasks, and team members.'
                    ),
                },
                {
                    title: __(
                        'تقارير خاصة بنسبة الإنجاز والملاحظات وتتبع سير المشروع.',
                        'Reports of the percentage of completion, observations and tracking the progress of the project.'
                    ),
                },
                {
                    title: __(
                        'التقييم النهائي للمشروع',
                        'Final evaluation of the project'
                    ),
                },
            ],
        },
        {
            title: __('التقويم', 'Calendar'),
            content: [
                {
                    title: __('التقويم ', 'Calendar'),
                    description: __(
                        'عرض التقويم الخاص باليوم والأسبوع والشهر .',
                        'View the calendar for the day, week, and month.'
                    ),
                },
                {
                    title: __('التذكيرات', 'Reminders'),
                    description: __(
                        'جدولت تذكيرات خاصة بالموظف .',
                        'Schedule employee specific reminders.'
                    ),
                },
                {
                    title: __('المهام', 'Reminders'),
                    description: __(
                        'تعين أو تكليف شخص آخر بمهمة ما مع إضافة مستندات وصور وفيديوهات و آلية عمل المهمة .',
                        'Assigning or assigning another person to a task with the addition of documents, photos and videos, and the mechanism of the task.'
                    ),
                },
            ],
        },
        {
            title: __('الأنشطة', 'Activities'),
            content: [
                {
                    title: __('المشاركات', 'Posts'),
                    description: __(
                        'صفحة شخصية للموظف – والتي يستطيع الموظف من خلالها التواصل مع الآخرين وتبادل المعلومات الشخصية والهوايات وتحميل الصور و الفيديوهات  وإنشاء مجموعات عامة أو خاصة.',
                        "An employee's personal page - through which the employee can communicate with others, exchange personal information and hobbies, upload photos and videos, and create public or private groups."
                    ),
                },
                {
                    title: __('التعميمات والحملات', 'Circulars and Campaigns'),
                    description: __(
                        'تخصيص التعميمات والحملات إلى موظف واحد أو مجموعة من الموظفين أو كل الموظفين مع تحديد المدة الزمنية و إختيار عدد مرات التكرار في اليوم وتحديد وقت النشر  مع إضافة متابعتها أو إلغائها أو كتابة تعليق أو البحث عن طريق عدة خيارات .',
                        'Allocating circulars and campaigns to one employee, group of employees, or all employees with specifying the time period and choosing the number of repetitions per day and determining the time of publication with adding or canceling their follow-up or writing a comment or searching by several options.'
                    ),
                },
                {
                    title: __('التحذيرات', 'Warnings'),
                    description: __(
                        'الآعلان عن الحالات الطارئة عن طريق إصدار صوت خاص بالتحذيرات مع الإشعار بالحالة و تحديد الموقع مع إمكانية ارفاق صورة أو مقطع مرئي من أجل إتخاذ الإحترازات الضرورية لتفادي الخسائر البشرية و المادية  إضافة إلى متابعتها أو إلغاءها أو كتابة تعليق أو البحث عن طريق عدة خيارات .',
                        'Announcing emergency cases by issuing a warning sound with a notification of the situation and specifying the location with the ability to attach a picture or video clip in order to take the necessary precautions to avoid human and material losses in addition to following up or canceling them or writing a comment or searching through several options.'
                    ),
                },
                {
                    title: __('التذكيرات', 'Reminders'),
                    description: __(
                        'عرض التذكيرات الخاصة بالموظف لمساعدته على إتمام الأعمال المجدولة .',
                        'View employee reminders to help them complete scheduled work.'
                    ),
                },
                {
                    title: __('المهام', 'Mission'),
                    description: __(
                        'عرض قائمة المهمات وتفاصيلها والتعليقات الخاصة بها .',
                        'View the list of tasks, their details, and comments.'
                    ),
                },
                {
                    title: __(
                        'الترحيب بالتعيين الجديد',
                        'Welcome the new appointment'
                    ),
                    description: __(
                        'نشر رسالة ترحيب بالموظف المعين حديثاً مع إظهار كامل البيانات (صورة شخصية ,المسمى الوظيفي ,المؤهلات ....) .',
                        'Posting a welcome letter to the newly appointed employee showing all information (personal photo, job title, qualifications ....).'
                    ),
                },
            ],
        },
        {
            title: __('الإدارة', 'Administration'),
            content: __(
                'كل ماله علاقة بالباقة.',
                "It's all about the package."
            ),
        },
        {
            title: __('التدريب لعدد 5 جلسات', 'five sessions training'),
            content: __(
                'من المهم جداً توفير التدريب للمستخدمين الجدد لمنصة أواصل , نقدم تدريب مجاني لعدد خمس جلسات لعدد غير محدد من الموظفين في المنشآت الخاصة للعميل أو عن بعد وتتناول المواد التدريبية وطرق استخدام عناصر منصة أواصل',
                "It is very important to provide training for new users of the platform. We offer free training for five sessions for an unlimited number of staff member at the client's facilities or remotely. Address ways and means to use AWASUL platform"
            ),
        },
        {
            title: __(
                'سعة التخذين 12GB للفرد',
                'The storage capacity is 12GB per person'
            ),
            content: __(
                'تمنح منصة أواصل لكل فرد من أفراد المنشأة سعة تخزين في الخادم (Server)مقداراها 12GB (100 GB إضافية لكل 10 ريال )',
                'Asel platform gives each member of the facility a storage capacity in the server of 12 GB - (an additional 100 GB for every 10 riyals)'
            ),
        },
    ];

    const secHeading = {
        textAlign: 'center',
        fontSize: ['30px', '30px', '36px', '40px'],
        fontWeight: '400',
        color: '#0f2137',
        letterSpacing: '-0.025em',
        ml: 'auto',
        mr: 'auto',
        lineHeight: '1.12',
        width: '540px',
        maxWidth: '100%',
    };

    const secText = {
        as: 'div',
        display: 'block',
        textAlign: 'center',
        fontSize: '17px',
        color: '#808081',
        mb: '20px',
    };

    return (
        <Container style={rootStyles} id="pricing">
            <Heading {...secHeading} content={__('الباقات', 'Packages')} />
            <Text
                {...secText}
                content={__(
                    '10 ريال لكل 100 GB إضافية',
                    '10 SAR, per 100 GB extra'
                )}
            />
            <Grid
                container
                justify="center"
                alignItems="flex-start"
                spacing={3}
            >
                <Grid item xs={12} md={6} lg={3}>
                    <PricingCardItem
                        title={__(
                            'باقة التواصل الداخلي',
                            'Internal communication package'
                        )}
                        price={__('13 ', '13')}
                        btnLabel={__('‏30 يوم استخدام مجاني', 'Days free 30')}
                        btnHref="/login"
                        ImageUrl={InternalIcon}
                        PackageDescription={__(
                            'للشركات الناشئة و المجموعات الصغيرة من أجل الحفاظ على بيئة وظيفية مترابطة',
                            'For startups and small groups in order to maintain a coherent functional environment'
                        )}
                        PackageFeatures={InternalCommunication}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <PricingCardItem
                        title={__('باقة الأعمال', 'business')}
                        price={__('17 ', '17')}
                        btnLabel={__('‏30 يوم استخدام مجاني', 'Days free 30')}
                        ImageUrl={CompanyIcon}
                        btnHref="/login"
                        PackageDescription={__(
                            'للشركات التي تعمل كفريق واحد من أجل تحسين أدائها',
                            'Companies that work as a team to improve their performance'
                        )}
                        PackageFeatures={Business}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <PricingCardItem
                        title={__(
                            'باقة الأعمال المحترفة',
                            'Professional business package'
                        )}
                        price={__('19 ', '19')}
                        btnLabel={__('30 يوم استخدام مجاني', 'Days free 30')}
                        ImageUrl={AnalyzeIcon}
                        btnHref="/login"
                        PackageDescription={__(
                            'لإدارة الأعمال الداخلية للشركات التي تعمل عن بعد لتحسين الإنتاج لكسب رضاء العميل وزيادة الدخل',
                            'To manage the internal business of companies that work remotely to improve production to gain customer satisfaction and increase income'
                        )}
                        PackageFeatures={ProfessionalBusiness}
                        recommendedMsg={__('الأكثر استخداما', 'Most used')}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <PricingCardItem
                        title={__('باقةالمنشآت', 'Enterprise')}
                        btnLabel={__('تواصل معنا', 'Connect us')}
                        btnHref="/contact"
                        ImageUrl={InstitutionsIcon}
                        PackageDescription={__(
                            'للهيئات و المنشآت التي تسعى إلى إدارة العمل والمشروعات على المستوى المؤسسي',
                            'Bodies and institutions that seek to manage work and projects at the institutional level'
                        )}
                        PackageFeatures={Enterprise}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Pricing;
