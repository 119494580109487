import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const BannerWrapper = styled.section`
    padding-top: 210px;
    padding-bottom: 160px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;
    position: relative;
    overflow: hidden;

    @media (max-width: 1311px) {
        min-height: 80vh;
    }

    @media (max-width: 1162px) {
        min-height: auto;
    }

    @media (max-width: 990px) {
        padding-top: 180px;
        padding-bottom: 60px;
    }
    @media (max-width: 767px) {
        padding-top: 130px;
        padding-bottom: 20px;
    }

    @media only screen and (max-width: 480px) {
        background: none;
    }

    .particle {
        position: absolute;
        width: 50%;
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        @media (max-width: 767px) {
            display: none;
        }
        @media only screen and (max-width: 480px) {
            width: 100%;
        }
    }

    .col {
        width: 50%;

        @media (max-width: 991px) {
            width: 55%;
        }

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .row {
        position: relative;
        z-index: 1;
    }

    .button__wrapper {
        margin-top: 40px;
        @media (max-width: 767px) {
            margin-bottom: 30px;
        }
        .reusecore__button {
            &.outlined {
                border-color: rgba(82, 104, 219, 0.2);
                @media (max-width: 375px) {
                    margin-top: 10px;
                }
            }
        }
    }
`;

const BannerObject = styled.div`
    position: absolute;
    height: 100%;
    top: 100px;
    ${({ lang }) => (lang === 'ar' ? 'left: 0;' : 'right: 0;')};
    width: 50%;
    @media (max-width: 1311px) {
        width: 40%;
    }
    @media (max-width: 991px) {
        width: 35%;
    }
    @media (max-width: 767px) {
        display: none;
    }
    .objectWrapper {
        margin-right: -50px;
        position: relative;
        .dashboardWrapper {
            margin-left: -50px;
            width: 1080px;
            @media (max-width: 1620px) {
                width: auto;
            }

            & > img {
                width: 100%;
            }

            .bannerImage {
                top: 40px;
            }
        }
        .shapeWrapper {
            ${({ lang }) => lang === 'ar' && 'transform: rotate(180deg);'}
        }
    }
`;

const DiscountLabel = styled.div`
    display: inline-block;
    border-radius: 4em;
    border: 1px solid ${themeGet('colors.lightBorder', '#f1f4f6')};
    padding: 7px 25px;
    box-shadow: 0 7px 25px 0 rgba(22, 53, 76, 0.05);
    margin-bottom: 30px;
    background-color: ${themeGet('colors.white', '#ffffff')};
    @media (max-width: 767px) {
        padding: 7px 15px;
    }
`;

export { DiscountLabel, BannerObject };

export default BannerWrapper;
