import styled from 'styled-components';

const AccessSectionWrapper = styled.div`
    justify-content: center;
    margin-top: 10px;
    display: flex;

    @media (max-width: 577px) {
        flex-direction: column;
        align-items: center;
    }
`;

export default AccessSectionWrapper;
