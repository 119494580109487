import React from 'react';
import { Collapse, ListItem, ListItemText } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { __ } from '../../utils/LanguageHelper';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const CollapseListItem = ({ title, content, checkIcon, sub }) => {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItem
                style={{
                    padding: sub
                        ? __('5px 15px 5px 0', '5px 0 5px 15px')
                        : '8px 0',
                }}
            >
                <div
                    onClick={() => handleClick()}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    {checkIcon && (
                        <CheckCircleIcon
                            style={{
                                fontSize: '16px',
                                color: '#1EB448',
                            }}
                        />
                    )}

                    <ListItemText
                        secondary={title}
                        style={{
                            textAlign: 'start',
                            fontSize: '12px',
                            fontWeight: '400',
                            color: '#5D5D5D',
                            padding: __('0 8px 0 0', '0 0 0 8px'),
                        }}
                    />
                    {content ? (
                        open ? (
                            <ExpandLess
                                style={{
                                    color: '#5D5D5D',
                                }}
                            />
                        ) : (
                            <ExpandMore
                                style={{
                                    color: '#5D5D5D',
                                }}
                            />
                        )
                    ) : null}
                </div>
            </ListItem>
            {content && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <div
                        style={{
                            padding:
                                sub && __('0px 30px 0px 0', '0px 0 30px 0px'),
                        }}
                    >
                        {content}
                    </div>
                </Collapse>
            )}
        </>
    );
};

export default CollapseListItem;
