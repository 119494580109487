import React from 'react';
import clsx from 'clsx';
import { Typography, Card, Button, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { __ } from '../../common/utils/LanguageHelper';
import { Link } from 'gatsby-plugin-intl';
import CollapseListItem from '../../common/components/CollapseListItem';

const useStyles = makeStyles((_) => ({
    root: {
        display: 'flex',
        height: 'auto',
        position: 'relative',
        flexDirection: 'column',
        minHeight: '940px',
        padding: '20px',
        boxShadow: '0px 15px 20px #DCE6F85C',
        transition: 'all 0.2s ease-out',
        '&:hover': {
            transform: 'translateY(-15px)',
            boxShadow: '0px 3px 16px 5px #dce6f8e0',
        },
    },
    isRecommended: {
        boxShadow: '0px 3px 39px -3px #b3c6e89c',
    },
    recommendedMsg: {
        backgroundColor: '#EF0121',
        transform: 'rotate(-32deg)',
        position: 'absolute',
        minWidth: '500px',
        padding: '9px',
        top: '28px',
        left: '-176px',
        '& > span': {
            color: '#fff',
        },
    },
    header: {
        width: '100%',
        textAlign: 'center',
        paddingBottom: '30px',
        borderBottom: '1px solid #DEDEDE',
    },
    img: {
        width: '100%',
        height: '100%',
    },
    imgContainer: {
        height: '110px',
        marginBottom: '20px',
    },
    middle: {
        textAlign: 'center',
        minHeight: '360px',
        padding: '30px 0',
        borderBottom: '1px solid #DEDEDE',
    },
    price: {
        fontSize: '30px',
        fontWeight: ' 700',
        color: '#000',
    },
    priceType: {
        marginBottom: '15px',
        fontSize: '25px',
        fontWeight: ' 700',
        color: '#000',
    },
    icon: {
        display: 'block',
        color: '#F0468C',
        fontSize: '70px',
        margin: '0 auto',
        marginBottom: '20px',
    },
    btn: {
        padding: '13px 35px',
        backgroundColor: '#1EB448',
        color: '#fff',
        boxShadow: '-2px 4px 12px -4px #1EB448',
        transition: '0.30s ease-out',
        marginBottom: '20px',
        '&:hover': {
            boxShadow: '0px 7px 18px -3px #1EB448',
            backgroundColor: '#1EB448',
        },
    },
    last: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '30px',
    },
}));

const PricingCardItem = ({
    title,
    price,
    btnLabel,
    ImageUrl,
    btnHref,
    PackageDescription,
    PackageFeatures,
    recommendedMsg,
    className,
}) => {
    const classes = useStyles();
    const isRecommended = recommendedMsg && classes.isRecommended;

    const RenderContent = (content) => {
        if (typeof content === 'object') {
            return content.map(({ title, description }) => {
                return (
                    <CollapseListItem
                        title={title}
                        content={
                            typeof description === 'object'
                                ? description.map((text) => (
                                      <div
                                          style={{ padding: '2px 0px 2px 0px' }}
                                      >
                                          {text}
                                      </div>
                                  ))
                                : description
                        }
                        sub
                    />
                );
            });
        } else {
            return content;
        }
    };

    return (
        <Card className={clsx(classes.root, isRecommended, className)}>
            <div className={classes.header}>
                {recommendedMsg && (
                    <div className={classes.recommendedMsg}>
                        <Typography
                            component="span"
                            style={{
                                fontWeight: 600,
                                fontSize: '17px',
                            }}
                        >
                            {recommendedMsg}
                        </Typography>
                    </div>
                )}
                <div className={classes.imgContainer}>
                    <img src={ImageUrl} alt={title} className={classes.img} />
                </div>

                <Typography
                    component="h2"
                    style={{
                        fontSize: '20px',
                        fontWeight: ' 600',
                        color: '#000',
                    }}
                >
                    {title}
                </Typography>
            </div>

            <div className={classes.middle}>
                {price && (
                    <>
                        <Typography component="h2" className={classes.price}>
                            {price}
                        </Typography>
                        <Typography
                            component="h2"
                            className={classes.priceType}
                        >
                            {__('ريال', 'SAR')}
                        </Typography>

                        <Typography
                            variant="h6"
                            component="p"
                            color="textSecondary"
                            style={{
                                marginBottom: '20px',
                                fontSize: '14px',
                                fontWeight: ' 600',
                                color: '#5D5D5D',
                            }}
                        >
                            {__('شهريا للفرد', 'Per person per month')}
                        </Typography>
                    </>
                )}

                {!price && <MailOutlineIcon className={classes.icon} />}

                <Link to={btnHref}>
                    <Button variant="contained" className={classes.btn}>
                        {btnLabel}
                    </Button>
                </Link>

                <Typography
                    component="p"
                    style={{
                        fontSize: '17px',
                        lineHeight: '30px',
                        fontWeight: ' 600',
                        color: '#5D5D5D',
                    }}
                >
                    {PackageDescription}
                </Typography>
            </div>

            <div className={classes.last}>
                <Typography
                    component="h1"
                    style={{
                        fontSize: '18px',
                        fontWeight: ' 600',
                        color: '#000',
                    }}
                >
                    {__('محتوي الباقة', 'Package features')}
                </Typography>

                <List>
                    {PackageFeatures.map(({ title, content }, idx) => (
                        <div key={`card-${idx}`}>
                            <CollapseListItem
                                title={title}
                                content={content && RenderContent(content)}
                                checkIcon
                            />
                        </div>
                    ))}
                </List>
            </div>
        </Card>
    );
};

export default PricingCardItem;
