import styled from "styled-components";

const ImageFrameWrapper = styled.div`
    margin-right: auto;
    position: relative;

    &:hover {
        opacity: 0.96;
    }

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 10px;
        top: 10px;
        transition: transform 330ms ease-in-out;
        background: linear-gradient(125deg, rgb(91 109 220 / 69%) 0%, rgb(135 139 218 / 74%) 100%);
        z-index: -1;
    }
    &:hover:before {
        transform: translate(-20px, -20px);
    }
`;

export default ImageFrameWrapper;
