import React from "react";
import ImageFrameWrapper from "./ImageFrame.style";
import Image from "../../common/components/Image";

export default function ImageFrame({ alt, imageUrl }) {
    return (
        <ImageFrameWrapper boxShadow='none'>
            <Image src={imageUrl} alt={alt} />
        </ImageFrameWrapper>
    );
}
