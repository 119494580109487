import React, { Fragment, useEffect, useState } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from '../common/theme/saas';
import { ResetCSS } from '../common/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/saas.style';
import Navbar from '../containers/Navbar';
import BannerSection from '../containers/BannerSection';
import SiteFeatures from '../containers/SiteFeatures';
import FeatureSlider from '../containers/FeatureSlider';
import UpdateScreen from '../containers/UpdateScreen';
import VisitorSection from '../containers/VisitorSection';
import ServicesSection from '../containers/ServicesSection';
import PartnerHistory from '../containers/PartnerHistory';
import AccessSection from '../containers/AccessSection';
import JoinTrail from '../containers/JoinTrail';
import Pricing from '../containers/Pricing';
import Footer from '../containers/Footer';
import { DrawerProvider } from '../common/contexts/DrawerContext';
import { __ } from '../common/utils/LanguageHelper';
import SEO from '../components/seo';
import { useIntl } from 'gatsby-plugin-intl';
import NmoLogo from '../common/assets/image/Nmo_logo.png';
// import TestimonialSection from '../containers/Testimonials';

import AwasulStoryImage from '../common/assets/image/DSC_4298.png';
import AwasulViewImage from '../common/assets/image/DSC_6601.png';

export default () => {
    const intl = useIntl();

    return (
        <ThemeProvider theme={saasTheme}>
            <Fragment>
                <SEO title={__('الرئيسية', 'Home')} />
                <ResetCSS />
                <GlobalStyle />
                <ContentWrapper>
                    <Sticky
                        top={0}
                        innerZ={9999}
                        activeClass="sticky-nav-active"
                    >
                        <DrawerProvider>
                            <Navbar isHomePage />
                        </DrawerProvider>
                    </Sticky>
                    <BannerSection lang={intl.locale} />
                    <SiteFeatures lang={intl.locale} />
                    <FeatureSlider />
                    <UpdateScreen />

                    <div style={{ padding: 5 }}>
                        <VisitorSection
                            fadeDelay={50}
                            id="Awasul_story"
                            title={__('قصة أواصل', "Awasul's Story")}
                            description={__(
                                'في كل مكان يتواجد فيه الناس لابد من وجود تواصل بينهم, ووسائل تنظم نشر المعلومات والمتسجدات بينهم من أجل أن يسير العمل بوتيرة أفضل وإيقاع متناغم أكثر , لابد وأن يكون هناك اتصال داخلي فعال ومنظم بين جميع العاملين في نظاق واحد أو مجال واحد أو شركة واحدة ككل. إن مشاركة المعلومات العامة يشكل جزء أساسي من العملية, لكن إبقاء الناس العاملين معك عاشقين لما يقومون به هو أمر يحث على التحدي أكثر وممتع بنفس الدرجة.',
                                'Everywhere people are, there must be communication between them, and means to organize the dissemination of information among them so that work can proceed at a better pace and at a more harmonious pace. There must be an effective and structured internal communication between all staff member in one area, or one firm.'
                            )}
                            imageUrl={AwasulStoryImage}
                            reverse
                        />
                        <br />
                        <VisitorSection
                            fadeDelay={100}
                            subTitle={__('رسالة أواصل', 'Our mission')}
                            title={__('رؤية أواصل', 'Our vision')}
                            description={__(
                                'أن تكون منصة التواصل الداخلي الأكثر استخداما في العالم العربي',
                                'To be the most widely used internal communication platform in the Arab world'
                            )}
                            subDescription={__(
                                'خلق ثقافة جديدة في التواصل الداخلي و الوصول إلى  أكبر عدد من المؤسسات في القطاعين العام والخاص داخل المملكة العربية السعودية خلال السنوات الثلاث الأولى.',
                                'Creating a new culture of internal communication and reaching the largest number of institutions, both public and private sectors within the Kingdom of Saudi Arabia during the first three years'
                            )}
                            imageUrl={AwasulViewImage}
                        />
                    </div>

                    <ServicesSection />
                    <AccessSection />
                    <Pricing />
                    <PartnerHistory />
                    {/*<TestimonialSection />*/}
                    <JoinTrail
                        title={__('شركة محتضنه في حاضنة نمو')}
                        imageUrl={NmoLogo}
                    />
                    <Footer />
                </ContentWrapper>
            </Fragment>
        </ThemeProvider>
    );
};
