import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Card from '../../common/components/Card';
import Image from '../../common/components/Image';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import FeatureBlock from '../../common/components/FeatureBlock';
import Container from '../../common/components/UI/Container';
import PartnerHistoryWrapper, { CounterUpArea } from './partnerHistory.style';
import { __ } from '../../common/utils/LanguageHelper';
import { Link } from 'gatsby-plugin-intl';
import NmoLogo from '../../common/assets/image/Nmo_logo.png';
import Awasul2Logo from '../../common/assets/image/awasul_2.png';
import WadiMakaLogo from '../../common/assets/image/wadi-maka.png';
// import EnergizeLogo from '../../common/assets/image/Energize_logo.png';

const PartnerHistory = ({
    row,
    col,
    cardStyle,
    title,
    className,
    description,
    btnStyle,
    cardArea,
}) => {
    const CLIENTS_DATA = [
        {
            id: 1,
            imageUrl: NmoLogo,
            title: 'نمو',
        },
        {
            id: 2,
            imageUrl: Awasul2Logo,
            title: 'أواصل الاستشارية',
        },
        {
            id: 3,
            imageUrl: WadiMakaLogo,
            title: 'شركة وادي مكة',
        },
        // {
        //     id: 4,
        //     imageUrl: EnergizeLogo,
        //     title: 'انرجايز',
        // },
    ];

    return (
        <PartnerHistoryWrapper id="clients" className={className}>
            <Container>
                <Box className="row" {...row}>
                    <Box
                        className="col"
                        {...col}
                        style={{ flexDirection: 'column' }}
                    >
                        <FeatureBlock
                            title={
                                <Heading
                                    content={__('عملائنا', 'Our Clients')}
                                    {...title}
                                />
                            }
                            description={
                                <Text
                                    content={__(
                                        'نحن في أواصل نؤمن بالشفافية في التواصل، ويسعدنا مشاركة أراء عملائنا في خدماتنا',
                                        'We at AWASUL believe in transparency in communication, and we are happy to share the views of our clients in our services.'
                                    )}
                                    {...description}
                                />
                            }
                            button={
                                <Link to="/share_experience">
                                    <Button
                                        title={__(
                                            'شاركنا تجربتك',
                                            'Leave feedback'
                                        )}
                                        {...btnStyle}
                                    />
                                </Link>
                            }
                        />
                    </Box>
                    <Box className="col" {...col} {...cardArea}>
                        <CounterUpArea>
                            {CLIENTS_DATA.map(({ id, title, imageUrl }) => (
                                <Card className="card" {...cardStyle} key={id}>
                                    <Image src={imageUrl} alt={title} />
                                </Card>
                            ))}
                        </CounterUpArea>
                    </Box>
                </Box>
            </Container>
        </PartnerHistoryWrapper>
    );
};

PartnerHistory.propTypes = {
    sectionHeader: PropTypes.object,
    sectionTitle: PropTypes.object,
    sectionSubTitle: PropTypes.object,
    row: PropTypes.object,
    col: PropTypes.object,
    cardStyle: PropTypes.object,
};

PartnerHistory.defaultProps = {
    row: {
        flexBox: true,
        flexWrap: 'wrap',
        ml: '-15px',
        mr: '-15px',
    },
    col: {
        pr: '15px',
        pl: '15px',
        width: [1, 1, 1 / 2, 1 / 2, 1 / 2],
        flexBox: true,
        alignSelf: 'center',
    },
    cardStyle: {
        p: '53px 40px 35px',
        borderRadius: '10px',
    },
    title: {
        fontSize: '50px',
        fontWeight: '400',
        color: '#0f2137',
        letterSpacing: '-0.010em',
        mb: '0',
        maxWidth: ['100%', '100%', '100%', '490px', '490px'],
    },
    description: {
        fontSize: '18px',
        color: '#343d48cc',
        lineHeight: '2.1',
        mb: '33px',
        textAlign: 'justify',
    },
    sectionSubTitle: {
        as: 'span',
        textAlign: 'left',
        fontSize: '14px',
        letterSpacing: '0.13em',
        fontWeight: '700',
        color: '#1a73e8',
        mb: '10px',
    },
    btnStyle: {
        minWidth: '156px',
        fontSize: '15px',
        fontWeight: '500',
        color: '#fff',
        borderRadius: '4px',
        pl: '22px',
        pr: '22px',
        colors: 'primaryWithBg',
    },
    cardArea: {
        pl: [0, 0, '40px', 0, 0],
    },
};

export default PartnerHistory;
