import React from "react";
import Image from "../../../common/components/Image";
import ServiceCardWrapper, { TextWrapper } from "./ServiceCard.style";

export default function ServiceCard({ title, description, imageUrl, lang }) {
    return (
        <ServiceCardWrapper lang={lang}>
            <Image src={imageUrl} className='image' alt={title} />
            <TextWrapper lang={lang}>
                <h2>{title}</h2>
                <p>{description}</p>
            </TextWrapper>
        </ServiceCardWrapper>
    );
}
