import styled from 'styled-components';

const ServiceCardWrapper = styled.div`
    margin-top: 16px;
    margin-left: 16px;
    flex-direction: column;
    text-align: center;
    border-radius: 5px;
    padding: 21px 46px;
    display: flex;
    align-items: center;
    background: #d0d0d026;
    transition: 4s all ease-in-out;

    @media (min-width: 1569px) {
        max-width: 300px;
    }

    @media (max-width: 1568px) {
        max-width: 250px;
    }

    @media (max-width: 1329px) {
        max-width: 230px;
    }

    @media (max-width: 1229px) {
        max-width: 500px;
    }

    @media (max-width: 831px) {
        flex-direction: column;
        padding: 18px 15px;
        text-align: center;
    }

    &:hover {
        background: linear-gradient(
            31deg,
            rgba(215, 178, 233, 0.4) 0%,
            rgb(83 105 220 / 18%) 100%
        );
    }

    .image {
        max-width: 50px;
        margin-bottom: 15px;
        @media (max-width: 871px) {
            max-width: 45px;
            margin-bottom: 0;
        }
    }
`;

export const TextWrapper = styled.div`
    ${({ lang }) =>
        lang === 'ar' ? 'margin-right: 17px;' : 'margin-left: 17px;'}
    h2 {
        font-size: 18px;
        margin: 0 0 2px;
        @media (max-width: 831px) {
            font-size: 16px;
        }
    }

    p {
        margin: 0;
        font-size: 14px;
        color: #343d48cc;
    }

    @media (max-width: 831px) {
        ${({ lang }) =>
            lang === 'ar' ? 'margin-right: 0;' : 'margin-left: 0;'}
        padding: 0 10px;
        margin-top: 15px;
    }
`;

export default ServiceCardWrapper;
