import React from 'react';
import Fade from 'react-reveal/Fade';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import FeatureBlock from '../../common/components/FeatureBlock';
import Container from '../../common/components/UI/Container';
import VisitorSectionWrapper, { SectionObject } from './visitor.style';
import ImageFrame from '../ImageFrame';

const VisitorSection = ({
    title,
    imageUrl,
    titleStyles,
    description,
    descriptionStyles,
    id,
    fadeDelay,
    subTitle,
    subDescription,
    reverse,
}) => {
    return (
        <Container>
            <Fade up delay={fadeDelay}>
                <VisitorSectionWrapper id={id} reverse={reverse}>
                    <SectionObject>
                        <ImageFrame imageUrl={imageUrl} alt={title} />
                    </SectionObject>
                    <div className="textWrapper">
                        {subTitle && subDescription && (
                            <FeatureBlock
                                title={
                                    <Heading
                                        content={subTitle}
                                        {...titleStyles}
                                    />
                                }
                                description={
                                    <Text
                                        content={subDescription}
                                        {...descriptionStyles}
                                    />
                                }
                            />
                        )}

                        <FeatureBlock
                            title={<Heading content={title} {...titleStyles} />}
                            description={
                                <Text
                                    content={description}
                                    {...descriptionStyles}
                                />
                            }
                        />
                    </div>
                </VisitorSectionWrapper>
            </Fade>
        </Container>
    );
};

VisitorSection.defaultProps = {
    textArea: {
        width: ['100%', '100%', '45%'],
        ml: [0, 0, '58%'],
    },
    titleStyles: {
        fontSize: ['20px', '26px', '26px', '36px', '43px'],
        fontWeight: '400',
        color: '#0f2137',
        mb: ['10px', '10px'],
        maxWidth: ['100%', '100%', '100%', '440px', '440px'],
        lineHeight: '1.5',
    },
    descriptionStyles: {
        textAlign: 'justify',
        fontSize: '16px',
        color: '#343d48cc',
        lineHeight: '1.75',
        mb: '20px',
        maxWidth: ['100%', '100%', '100%', '440px', '440px'],
    },
};

export default VisitorSection;
