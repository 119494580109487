import styled from 'styled-components';

const SectionWrapper = styled.section`
    padding: 60px 0 70px;
    @media screen and (max-width: 1440px) {
        padding: 70px 0;
    }
    @media screen and (max-width: 768px) {
        padding: 50px 0;
    }
    @media screen and (max-width: 480px) {
        padding: 60px 0 10px;
    }
`;

export const Section = styled.div`
    display: flex;
    align-items: flex-start;

    @media (max-width: 1074px) {
        flex-direction: column;
    }
`;

export const Illustration = styled.figure`
    width: 50%;
    position: relative;
    ${({ lang }) =>
        lang === 'ar' ? 'margin: 0 0 0 5%;' : 'margin: 0 5% 0 0;'};
    @media (max-width: 1074px) {
        margin-bottom: 52%;
        width: 100%;
    }
    @media (max-width: 991px) {
        margin-bottom: 72%;
        width: 100%;
    }
    & > img {
        width: 630px;
    }
`;

export const Content = styled.div`
    width: 50%;
    @media (max-width: 1074px) {
        width: 100%;
    }
    @media only screen and (max-width: 768px) {
        width: 100%;
        margin-top: 30px;
    }
    @media only screen and (max-width: 480px) {
        margin-top: 0;
    }
    h2 {
        font-weight: 400;
        font-size: 40px;
        line-height: 60px;
        @media only screen and (min-width: 1024px) and (max-width: 1366px) {
            font-size: 30px;
            line-height: 50px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            font-size: 22px;
            line-height: 40px;
        }
        @media only screen and (max-width: 768px) {
            font-size: 24px;
            line-height: 42px;
        }
        @media only screen and (max-width: 480px) {
            font-size: 20px;
            line-height: 36px;
            font-weight: 700;
        }
    }
    p {
        font-size: 16px;
        line-height: 42px;
        @media only screen and (min-width: 1024px) and (max-width: 1366px) {
            line-height: 32px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            line-height: 28px;
        }
        @media only screen and (max-width: 768px) {
            line-height: 32px;
        }
    }
`;

export const ListGroup = styled.ul`
    margin-top: 20px;

    .list-item {
        display: flex;
        align-items: center;
        font-size: 17px;
        font-weight: bold;

        @media (max-width: 543px) {
            font-size: 16px;
        }

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    .list-icon {
        color: #53a653;
        -webkit-animation: scaling 1s infinite alternate;
    }

    @keyframes scaling {
        from {
            -webkit-transform: scale(1);
        }
        to {
            -webkit-transform: scale(1.2);
        }
    }
`;

export default SectionWrapper;
