import React, { useEffect, useState } from 'react';
import Image from '../Image';

const ImagesTransition = ({ images, ...props }) => {
    const [activeImage, setActiveImage] = useState(1);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (activeImage + 1 > images.length) {
                setActiveImage(1);
            } else {
                setActiveImage(activeImage + 1);
            }
        }, 2500);
        return () => clearTimeout(timer);
    }, [activeImage]);

    return (
        <>
            {images.map(({ title, imageUrl }, idx) => (
                <Image
                    src={imageUrl}
                    alt={title}
                    key={idx + 1}
                    style={{
                        position: 'absolute',
                        transition: 'opacity 1s ease-in-out',
                        opacity: activeImage === idx + 1 ? 1 : 0,
                    }}
                    {...props}
                />
            ))}
        </>
    );
};

export default ImagesTransition;
