import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import 'react-image-gallery/styles/css/image-gallery.css';
import Container from '../../common/components/UI/Container';
import FeatureSliderWrapper from './featureSlider.style';
import { __ } from '../../common/utils/LanguageHelper';

import ActivityScreen from '../../common/assets/image/Awasul_app_screens/Activity_app_screen.png';
import ChatScreen from '../../common/assets/image/Awasul_app_screens/Chat_app_screen.png';
import GroupsScreen from '../../common/assets/image/Awasul_app_screens/Groups_app_screen.png';
import TasksScreen from '../../common/assets/image/Awasul_app_screens/Tasks_app_screen.png';
import WarningsScreen from '../../common/assets/image/Awasul_app_screens/Warnings_app_screen.png';

import ActivityIcon from '../../common/assets/image/Awasul_app_icons/Activity_app_icon.svg';
import AlertsIcon from '../../common/assets/image/Awasul_app_icons/Alerts_app_icon.svg';
import ChatIcon from '../../common/assets/image/Awasul_app_icons/Chat_app_icon.svg';
import GroupsIcon from '../../common/assets/image/Awasul_app_icons/Groups_app_icon.svg';
import TasksIcon from '../../common/assets/image/Awasul_app_icons/Tasks_app_icon.svg';
import NotificationIcon from '../../common/assets/image/Awasul_app_icons/Notification_app_icon.svg';

const FeatureSlider = ({
    sectionSubTitleStyles,
    sectionTitleStyles,
    sectionHeaderStyles,
}) => {
    const images = [
        {
            original: `${WarningsScreen}`,
            thumbnail: `${AlertsIcon}`,
            thumbnailLabel: __('التحذيرات', 'Warnings'),
            originalAlt: 'slide one',
            thumbnailAlt: 'thumb one',
        },
        {
            original: `${ChatScreen}`,
            thumbnail: `${ChatIcon}`,
            thumbnailLabel: __('المحادثات', 'Chat'),
            originalAlt: 'slide two',
            thumbnailAlt: 'thumb two',
        },
        {
            original: `${TasksScreen}`,
            thumbnail: `${TasksIcon}`,
            thumbnailLabel: __('المهام', 'Tasks'),
            originalAlt: 'slide three',
            thumbnailAlt: 'thumb three',
        },
        {
            original: `${WarningsScreen}`,
            thumbnail: `${NotificationIcon}`,
            thumbnailLabel: __('التنبيهات', 'Notifications'),
            originalAlt: 'slide four',
            thumbnailAlt: 'thumb four',
        },
        {
            original: `${GroupsScreen}`,
            thumbnail: `${GroupsIcon}`,
            thumbnailLabel: __('المجموعات', 'Groups'),
            originalAlt: 'slide five',
            thumbnailAlt: 'thumb five',
        },
        {
            original: `${ActivityScreen}`,
            thumbnail: `${ActivityIcon}`,
            thumbnailLabel: __('الأنشطة', 'Activities'),
            originalAlt: 'slide six',
            thumbnailAlt: 'thumb six',
        },
    ];

    return (
        <FeatureSliderWrapper>
            <div className="FeatureSliderInner">
                <span> </span>
                <span> </span>
                <span> </span>
            </div>
            <Container>
                <Box {...sectionHeaderStyles}>
                    <Heading
                        {...sectionTitleStyles}
                        content={__('مميزات التطبيق', "Our App's Features")}
                    />
                    <Text
                        {...sectionSubTitleStyles}
                        content={__(
                            'مكتبك في جيبك',
                            'Your office in your pocket'
                        )}
                    />
                </Box>
                <Box className="FeatureSlider">
                    <ImageGallery
                        items={images}
                        className="Slider-img"
                        showPlayButton={false}
                        showFullscreenButton={false}
                        showNav={false}
                        showBullets={true}
                        autoPlay={true}
                    />
                </Box>
            </Container>
        </FeatureSliderWrapper>
    );
};

// FeatureSlider style props
FeatureSlider.propTypes = {
    sectionSubTitleStyles: PropTypes.object,
    sectionTitleStyles: PropTypes.object,
    sectionHeaderStyles: PropTypes.object,
};

// FeatureSlider default style
FeatureSlider.defaultProps = {
    sectionHeaderStyles: {},
    sectionSubTitleStyles: {
        as: 'span',
        display: 'block',
        textAlign: 'center',
        fontSize: '17px',
        fontWeight: '700',
        color: '#5d6edc',
        mb: '0',
    },
    // section title default style
    sectionTitleStyles: {
        textAlign: 'center',
        fontSize: '40px',
        fontWeight: '400',
        color: '#0f2137',
        letterSpacing: '-0.025em',
        mb: '0',
    },
};

export default FeatureSlider;
