import React from 'react';
import Fade from 'react-reveal/Fade';
import Image from '../../common/components/Image';
import Heading from '../../common/components/Heading';
import JoinTrailArea, { ContentWrapper } from './joinTrail.style';

const JoinTrail = ({ title, imageUrl }) => {
    return (
        <JoinTrailArea id="trail">
            <ContentWrapper>
                <Fade up delay={100}>
                    <Heading content={title} />
                </Fade>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Fade up>
                        <Image
                            src={imageUrl}
                            alt={title}
                            style={{
                                maxWidth: '250px',
                                marginBottom: '10px',
                            }}
                        />
                    </Fade>
                </div>
            </ContentWrapper>
        </JoinTrailArea>
    );
};

export default JoinTrail;
